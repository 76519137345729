import React from "react";
import { BsChevronDown } from "react-icons/bs";
const SideNav = () => {
  return (
    <div className="sticky relative  ">
      <div className="sidebar py-2 ">
        <div class="input-group mb-2 ps-2 ">
          <input
            type="text"
            class="form-control border-0 primary-color text-white"
            placeholder="Search for game"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <span
            class="input-group-text border-0 primary-color "
            id="basic-addon2"
          >
            <i class="bi bi-search text-danger sticky"></i>
          </span>
        </div>
        <div className="games-list p-2 footer-bg-color sidenav-left overflow-auto">
          {/* <div className="games-card p-1 mb-2 accordion " id="accordionExample">
          
            <div class="accordion accordion-flush " id="accordionFlushExample">
              <div class="accordion-item primary-color">
                <h2
                  class="accordion-header primary-color "
                  id="flush-headingOne"
                >
                  <button
                    class="accordion-button collapsed sidebar-accordion"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <div
                      className="
                    d-flex flex-row justify-content-between px-2 
                   "
                    >
                      <img
                        src="/assets/icons/trophy.svg"
                        className="game-icons "
                        alt=""
                      />
                      <p className="text-white fw-small my-auto"> Top League</p>
                    </div>
                  </button>
                </h2>

                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div id="flush-headingOne-one">
                    <button
                      class="accordion-button btn-primary-color"
                      type="button"
                      data-bs-toggle="collapse-one"
                      data-bs-target="#collapseOne-one"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne-one"
                    >
                      <img
                        src="/assets/icons/trophy.svg"
                        className="game-icons me-3"
                        alt=""
                      />
                      <p className="text-white fw-small my-auto"> Top League</p>
                    </button>
                  </div>

                  <div
                    id="flush-collapseOne-one"
                    class="accordion-collapse collapse-one"
                    aria-labelledby="flush-heading"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="d-flex">
                      <img
                        src="/assets/icons/star.svg"
                        alt="start"
                        className="text-white"
                      />
                      <span className="text-white">Asian Games - Women</span>
                      <img
                          src="/assets/icons/svgexport-10.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/trophy.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto"> Top League</p>
                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-8.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto"> BasketBall</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-9.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">World</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/football.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto"> FootBall</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/3x3basketball.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">3x3 BasketBall</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-11.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Tennis</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-12.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">VolleyBall</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-13.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">BaseBall</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-14.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Ice Hockey</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-15.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto"> MMA</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-16.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">HandBall</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-17.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Cricket</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-18.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Table Tennis</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-19.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Futsal</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-20.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto"> Snooker</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-21.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Water Polo</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-22.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Rugby Union</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-23.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Rugby League</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-24.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Darts</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-25.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Boxing</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-26.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Aussie Rules</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-27.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Golf</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>

          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-28.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Formula 1</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-29.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Cycling</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-30.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">Biathlon</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>

          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-31.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">NASCAR</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
          <div className="games-card  p-1 mb-2">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              <div className="d-flex flex-row justify-content-between px-2">
                <img
                  src="/assets/icons/svgexport-32.svg"
                  className="game-icons "
                  alt=""
                />
                <p className="text-white fw-small my-auto">American Football</p>

                <BsChevronDown className="text-white my-auto" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
