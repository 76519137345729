import React from "react";

const Trusted = () => {
  return (
    <div className="p-3">
      <div className="">
        <div className="primary-color p-2 fs-5 text-center">
          Winzir: Trusted and Secure Online Sports Betting and Casino Licensed
          by PAGCOR
        </div>
        <p className="fs-6">
          Welcome to WinZir, the leading online casino and sports betting site
          in the Philippines, proudly endorsed by Derek Ramsay. As a PAGCOR
          licensed and regulated platform, we offer a comprehensive range of
          features and benefits to provide you with an exceptional online
          gambling experience. Whether you're a Philippine online casino player
          or a sports bettor, WinZir is the perfect choice for you. With an
          extensive selection of games, including classic table games, immersive
          live dealer options, and a variety of sports betting opportunities, we
          cater to all preferences. Join WinZir today and discover why we are
          the premier destination for e-games, sports betting and e-bingo
          players in the Philippines. ...{" "}
          <span>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-decoration-none text-danger"
            >
              {" "}
              Read More
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Trusted;
