import React from "react";
import { Link } from "react-router-dom";
const ArticleOne = () => {
  return (
    <div className="my-3">
      <div>
        <img
          src="https://winzir.ph/fs/userFiles-v2/winzirv2-18753775/images/a-1690-24-7-withdrawal-16961330443447.webp?v=1696133132"
          alt="withdraw"
          className="w-100"
          srcset=""
        />
        <div className="d-flex mt-3 justify-content-center mx-auto">
          <Link
            to="https://bj88ph.live/af/Zd946zCP/winzir-official"
            className="w-100 d-flex justify-content-center text-decoration-none text-white"
          >
            <button className="w-75 btn btn-danger "> Load More</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArticleOne;
