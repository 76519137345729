import React from "react";

const Gameslist = () => {
  return (
    <>
      <div className="container mt-4">
        <div className="title d-flex justify-content-between pb-3">
          <span className="h4 fw-medium">Live Dealer</span>
          <span className="h6 fw-medium">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              View All (58)
            </a>{" "}
          </span>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row row-cols-6">
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/7eb0a5bb930fd75cc8173020c19ca46d_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/5862ad95cb3499606cc0661e058ceaaf_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/a62370b72aea2b1116a11dbd18f619e9_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/44dc86655b719616aa209a76e9f6438f_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/e8deec7ebf336d1fd789c11e2e54239a_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/47d1644bf70ed0f2e2666717beb43a31_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="title d-flex justify-content-between pb-3">
          <span className="h4  fw-medium">Table Games</span>
          <span className="h6  fw-medium">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              View All (58)
            </a>{" "}
          </span>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row row-cols-6">
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/4f8211ff17622d5798219929ff076d6f_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col ">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/081de5fbd30c78eed45c767c188ba2dc_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/9bfbde06a567f56e467f6fd9b6ea2771_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/e270d93e6d211f7bc2513796205758b9_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/797c1610110fa2e4c9eb1f9e976f37e8_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/a141513709e9b117ac5db470a3763ea0_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="title d-flex justify-content-between pb-3">
          <span className="h4  fw-medium">Video Slots</span>
          <span className="h6  fw-medium">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              View All (58)
            </a>{" "}
          </span>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row row-cols-6">
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/5fc28c5806bd3a0b0d186f6f5990c246_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/bdf7e8491331071911eb6da897ff2035_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/b54b0a103bdfb45bed68e31d6507f2bc_casinoGameIcon3.gif?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/536f695c34a9cdb764ca134b0d3359d0_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/bbf3ca966ddbd5eb9f43449ba28837c7_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/049b51351578c1776033744545d10d66_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="title d-flex justify-content-between pb-3">
          <span className="h4  fw-medium">Fishing & Hunting</span>
          <span className="h6  fw-medium">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              View All (58)
            </a>{" "}
          </span>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row row-cols-6">
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/f6ae5f140d1fa4efb25b53feb28039c5_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/6ccf173ac42decbf449141e7dc3a8344_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/cc735965f08f6e91b16dd023b86eab0f_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/a3a3437f710207f0852048b843222a85_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/387446fe9d6230170aab4b1caea53f05_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/582d50da581bb2caa85c9884fc24311d_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="title d-flex justify-content-between pb-3">
          <span className="h4  fw-medium">Arcade Games</span>
          <span className="h6  fw-medium">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white text-decoration-none"
            >
              View All (58)
            </a>{" "}
          </span>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row row-cols-6">
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/71004e7532e6f1ed63184dd3b393ffc1_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/6ccf173ac42decbf449141e7dc3a8344_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/cc735965f08f6e91b16dd023b86eab0f_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/6b15184429ece351bb35ce5e8992bc7b_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/4234903358f4e2d10ce7a1bcf69ceb45_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
          <div className="col">
            <img
              className="rounded-4"
              src="https://cmsbetconstruct.com/content/images/casino/icon3/37aee0b227d5866c80344bbc5c72fc40_casinoGameIcon3.webp?v=1695908221"
              alt="games"
              width={148}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gameslist;
