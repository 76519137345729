import React from "react";
import SideNav from "../components/ui-components/SideNav";
import "../styles/sidebar.css";
import "../styles/content.css";
import Content from "../components/ui-components/Content";
import RightContent from "../components/ui-components/RightContent";
const Home = () => {
  return (
    <div className="">
      <div className="d-flex relative">
        <div className="col-2 ">
          <SideNav />
        </div>

        <div
          className="center-content col-8 "
        >
          <Content />
        </div>
        <div
          className="right-content col-2 overflow-y-auto"
        >
          <RightContent />
        </div>
      </div>
    </div>
  );
};

export default Home;
