import React from "react";
import Video from "./RightContent/Video";
import ArticleOne from "./RightContent/ArticleOne";
import RecentWinner from "./RightContent/RecentWinner";
import { Link } from "react-router-dom";

const RightContent = () => {
  return (
    <div className=" right-content overflow-y-auto ">
      <div className="row row-col-1 m-2 primary-color">
        <div className="primary-color-light rounded-2 fw-medium fs-5 p-2 text-center">
          Betslip
          <span className="bg-danger badge rounded-5 p-1 my-auto ms-2">0</span>
        </div>
        <div className="primary-color-light rounded-2 fw-medium fs-5 p-2 text-center mt-1">
          <div className="row px-2">
            <div className="col">
              <select
                class="form-select primary-color-light text-white border-secondary"
                aria-label="Default select example"
              >
                <option selected>Single</option>
                <option value="1">Multiple</option>
                <option value="2">System</option>
                {/* <option value="3">System</option> */}
              </select>
            </div>
            <div className="col d-flex justify-content-end my-auto ">
              <Link to="https://bj88ph.live/af/Zd946zCP/winzir-official">
                <button className="btn rounded-2 text-white primary-color py-0 p-1">
                  <i class="bi bi-gear-fill  fs-5"></i>
                </button>
              </Link>
              <Link to="https://bj88ph.live/af/Zd946zCP/winzir-official">
                <button className="btn rounded-2 text-white primary-color py-0 p-1 ms-2">
                  <i class="bi bi-trash3-fill"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center ">
          <img src="/assets/icons/svgexport-44.svg" alt="noimage" />
        </div>
        <div className="text-center">
          <h3 className="text-danger">No Bets yet</h3>
          <p>There are no bets placed yet. Make your bets to see them here.</p>
        </div>
      </div>
      <div className="mb-5">
        <Video />
        <ArticleOne />
        <RecentWinner />
      </div>
    </div>
  );
};

export default RightContent;
