import React from "react";
import { BsCircleFill } from "react-icons/bs";
import Gameslist from "./Content/Gameslist";
import Trusted from "./Content/Trusted";
const Content = () => {
  return (
    <>
      <div className="content-section section-gamelist overflow-y-auto ">
        <div className="p-2">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="/assets/2786-a-banner-1699010202797.webp"
                  class="d-block w-100"
                  alt="carousel1"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-a-banner-16987585566622.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-a-banner-16990101631252.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-a-banner-16990102383364.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-a-banner-16990102672828.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-a-banner-16990102960003.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-winzir-weekly-raffle-card-banner-1400x328-3-16966460020889.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-winzir-weekly-raffle-card-banner-1400x328-3-16966460020889.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="/assets/2786-winzir-11-11-live-casino-bonus-giveaway-desktop-16988203469806.webp"
                  class="d-block w-100"
                  alt="Winzir game"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="px-2 ">
          <div className="primary-color fw-bold rounded-2">
            <div className="event-tabs ">
              <div class="nav nav-pills nav-fill p-2">
                <a
                  class="nav-link text-white primary-color-active"
                  aria-current="page"
                  href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                >
                  <BsCircleFill size={8} className="text-danger me-2" />
                  Live Events
                </a>
                <a
                  class="nav-link  text-white"
                  href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                >
                  Prematch
                </a>
                <a
                  class="nav-link  text-white"
                  href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                >
                  Popular Events
                </a>
              </div>
            </div>
          </div>
          <div className="event-header primary-color-active my-1 py-2">
            <div className="d-flex justify-content-between px-3">
              Live Events <span className="text-danger"> See All</span>
            </div>
          </div>
          <div className="event-sports primary-color fw-bold  rounded-2 p-2 my-1">
            <div className="d-flex">
              <div className="events-sport-active  px-3 py-2 rounded-3 ">
                <img
                  src="/assets/icons/svgexport-8.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="">Basketball</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/football.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Tennis</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-11.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Volleyball</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-12.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Volleyball</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-13.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Baseball</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-14.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Ice Hockey</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-18.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Table Tennis</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-19.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">Futsal</span>
              </div>
              <div className=" px-3 py-2 rounded-3  px-3 ">
                <img
                  src="/assets/icons/svgexport-32.svg"
                  className="game-icons me-2"
                  alt=""
                />
                <span className="d-none">American Football</span>
              </div>
            </div>
          </div>
          <div className="event-games primary-color  rounded-2 p-2">
            <div className="px-2 d-flex justify-content-between border-bottom border-secondary">
              <p>League - Woman</p>
              <div>
                <BsCircleFill size={6} className="text-danger me-2" />
                <span className=""> Quarter End</span>
              </div>
            </div>
            <div className="d-flex justify-content-between my-3">
              <div className="">
                <span className="rounded-5 primary-color bg-secondary p-1 me-2">
                  {" "}
                  DE
                </span>
                Defensor Sporting (Wom)
              </div>
              <div className="">
                <span className="rounded-5 primary-color p-1 me-2">0</span>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Gameslist />
          <Trusted />
        </div>
      </div>
    </>
  );
};

export default Content;
