import React from "react";
import { Component, useState, useEffect } from "react";
import "../header/header.css";
import { AiFillGift } from "react-icons/ai";
import { Link } from "react-router-dom";
const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    {
      id: 1,
      text: "Live",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 2,
      text: "Sports",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 3,
      text: "Games",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 4,
      text: "Live Casino",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 5,
      text: "E-Bingo",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 6,
      text: "V-Sports",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 7,
      text: "Promo",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
    {
      id: 8,
      text: "Blast",
      href: "https://bj88ph.live/af/Zd946zCP/winzir-official",
    },
  ];

  return (
    <div className=" px-0">
      {/* if user scroll the mouse will this div is removed with animates of slide. */}
      <div className=" container-fluid">
        <div className="row">
          <div className="col py-2 ms-3">
            <div className="module-logo">
              <a href="https://bj88ph.live/af/Zd946zCP/winzir-official">
                <img
                  src="https://winzir.ph/fs/userFiles-v2/winzirv2-18753775/images/logo.svg?v=1677570428"
                  alt="logo of website"
                />
              </a>
            </div>
          </div>
          <div className="col py-2 d-flex justify-content-end">
            <div className="me-3 d-flex justify-content-end">
              <Link to="https://bj88ph.live/af/Zd946zCP/winzir-official">
                <button className="btn btn-secondary bg-tertiary text-white me-3">
                  Login
                </button>
              </Link>
              <Link to="https://bj88ph.live/af/Zd946zCP/winzir-official">
                <button className="btn btn-danger text-white">Register</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* end of div */}
      <div className={`footer-bg-color ${scrolled ? "fixed-top" : ""}`}>
        <nav class="navbar navbar-expand-lg bg-tertiary">
          <div class="container-fluid">
            <div className="row w-100">
              <div className="col-8 flex-fill my-auto ">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav menu">
                    {navItems.map((item) => (
                      <li key={item.id} className="nav-item">
                        <a
                          className="nav-link  text-white text-uppercase"
                          href={item.href}
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle text-white text-uppercase"
                        href=""
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // no = "r"
                      >
                        More
                      </a>
                      <ul
                        class="dropdown-menu bg-black"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Raffle
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Betshops
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            News
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Sponsorships
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            About
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Regulatory
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            App Download
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Contact Us
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Forum
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-white text-uppercase"
                            href="#"
                          >
                            Tournaments
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-4 flex-fill d-flex justify-content-end pe-0
              "
              >
                <div className="row ">
                  <div className="col-3 d-flex justify-content-end my-auto px-0">
                    <AiFillGift size={35} color="red" />
                  </div>
                  <div className="col-9 my-auto d-flex justify-content-end">
                    <div className="row justify-content-end ms-0">
                      <div className="col-12 px-0">
                        <span className="text-uppercase licensed ">
                          Licensed by pagcor
                        </span>
                      </div>
                      <div className="col-12 px-0 ">
                        {" "}
                        <span className="text-time ">
                          {currentTime.toLocaleTimeString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div
        className={`nav_alternative ${scrolled ? "block" : "display_none"}`}
      ></div>
    </div>
  );
};

export default Header;
