import React from "react";

const RecentWinner = () => {
  return (
    <div>
      <div className="primary-color rounded-3 m-1 p-2">
        <div className="d-flex justify-content-center">
          <div className="border-bottom border-danger me-3 py-2">
            <img src="/assets/icons/svgexport-45.svg" alt="icons" />
            <span>Top Winners</span>
          </div>
          <div className=" py-2">
            <img src="/assets/icons/svgexport-46.svg" alt="icons" srcset="" />
            <span>Recent Winners</span>
          </div>
        </div>
        <div className="recent-winner-list overflow-x-auto ">
          <div className="d-flex">
            <img
              src="https://cmsbetconstruct.com/content/images/casino/icon3/44dc86655b719616aa209a76e9f6438f_casinoGameIcon3.webp?v=1695908221"
              alt="images"
              className="w-25 object-fit-contain me-3"
            />
            <div className="d-flex flex-column">
              <span>Sunny Fruits</span>
              <span>101****79331</span>
              <span className="text-success">PHP 134250</span>
            </div>
          </div>
          <div className="d-flex">
            <img
              src="https://cmsbetconstruct.com/content/images/casino/icon3/87ea67493fa0470d7b8e109627dfbca3_casinoGameIcon3.webp?v=1695908221"
              alt="images"
              className="w-25 object-fit-contain me-3"
            />
            <div className="d-flex flex-column">
              <span>Lightning Baccarat</span>
              <span>101****79331</span>
              <span className="text-success">PHP 134250</span>
            </div>
          </div>
          <div className="d-flex">
            <img
              src="https://cmsbetconstruct.com/content/images/casino/icon3/5862ad95cb3499606cc0661e058ceaaf_casinoGameIcon3.webp?v=1695908221"
              alt="images"
              className="w-25 object-fit-contain me-3"
            />
            <div className="d-flex flex-column">
              <span>Speed Baccarat</span>
              <span>101****79331</span>
              <span className="text-success">PHP 134250</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentWinner;
