import React from "react";
import { Link } from "react-router-dom";
const Video = () => {
  return (
    <div className=" px-2">
      <div class="w-100 py-2">
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/qy12Q5JluKM"
          title="Level Up Your Gaming Experience with WinZir: Win From Within!"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div>
        <Link to="https://bj88ph.live/af/Zd946zCP/winzir-official">
          <button className="btn primary-color text-center mx-auto w-100 text-white fs-5 fw-bold">
            News
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Video;
