import React from "react";

const Footer = () => {
  return (
    <footer className="footer-bg-color">
      <div className="container-fluid ">
        <div className="row py-2 border-bottom border-secondary py-3">
          <div className="col ">
            <img
              src="https://winzir.ph/fs/userFiles-v2/winzirv2-18753775/images/1235-keep-it-fun-new-pagcor-16944880098331.webp"
              alt="Footer imge"
              height={52}
              width={210}
            />
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-facebook fs-4 me-4"></i>
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-twitter-x fs-4 me-4"></i>
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-instagram fs-4 me-4"></i>
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-youtube fs-4 me-4"></i>{" "}
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-telegram fs-4 me-4"></i>{" "}
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-browser-chrome fs-4 me-4 "></i>{" "}
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/winzir-official"
              className="text-white"
            >
              <i class="bi bi-tiktok fs-4 me-4"></i>{" "}
            </a>
          </div>
        </div>
        <div className="container-fuild  ">
          <div className="row border-bottom border-secondary py-2 pb-5">
            <div className="col-4">
              <p
                className="footer-context hl-small pe-5 ps-1 pb-2"
                //  style="line-height: 1.3; font-size: 12px;"
              >
                WinZir is a registered trademark of Sandbox Entertainment Corp.,
                holding a unified remote gaming license issued by the Philippine
                Amusement and Gaming Corporation (PAGCOR), a 100 percent
                government-owned corporation under the direction of the
                Philippine President. You are strictly prohibited from wagering
                on this website if you are under 21 years of age. Gaming can be
                addictive. Please play responsibly. If you or any of your family
                members are experiencing gaming-related issues, please contact
                keepitfun@pagcor.ph. By using or signing in to this platform,
                you confirm that you understand and have read and agreed to our
                terms and conditions.
              </p>
              <div
                class="module-container   only-mobile   align-left ModuleTitle
                                                         "
                // style=" padding: 0px 80px 0px 20px  ; padding-bottom:1.0917030567686vh ; "
                aria-label="module container"
                role="contentinfo"
                id="m592"
              >
                <div class="   module ModuleTitle " datamodule-id="592">
                  <div class="uc-content">
                    <h3
                      className="fs-6 "
                      // style="text-align: left; line-height: 1.3; font-size: 12px"
                    >
                      <span
                        className="fs-6"
                        //  style="font-size: 12px; color: #ffffff; letter-spacing: 0px;"
                      >
                        Copyright © 2022-2023 WinZir. All Rights Reserved.{" "}
                      </span>
                    </h3>
                    <h3
                      className="fs-6"
                      // style="text-align: left; line-height: 1.3; font-size: 10px;"
                    >
                      <span
                      //  style="font-size: 12px; color: #ffffff; letter-spacing: 0px;"
                      >
                        PAGCOR License # 22-1409 and 22-1408
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div
                class="module-container   only-mobile   align-left ModuleTitle
                              last                             "
                // style=" padding: 0px 80px 0px 20px  "
                aria-label="module container"
                role="contentinfo"
                id="m2600"
              >
                <div class="   module ModuleTitle " datamodule-id="2600">
                  <div class="uc-content">
                    <h3
                    // style="font-size: 12px;"
                    >
                      <span
                      // style="font-size: 12px;"
                      >
                        <a
                          className="fs-6 text-danger text-decoration-none"
                          href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                        >
                          🔗 Switch to V1
                        </a>
                      </span>
                    </h3>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="col-2">
              <h5>Products</h5>

              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    {" "}
                    Live{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Games
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    E-Bingo
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Live Casino
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    V-Sports
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-2">
              {" "}
              <h5>Company</h5>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Promo
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Sponsorships
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Forum
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-2">
              {" "}
              <h5>Terms of Use</h5>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Regulatory
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Responsible Gaming
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://bj88ph.live/af/Zd946zCP/winzir-official"
                  >
                    Sportsbook Rules
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <div>
                <h5>Download App </h5>
                <img
                  src="	https://winzir.ph/fs/userFiles-v2/winzirv2-18753775/images/2490-winzir-app-qr-16793655461186.webp"
                  width="200px"
                  height="200px"
                  alt="qr code"
                />
              </div>
            </div>
          </div>

          <div className="row py-3 ">
            <div className="col-8">
              <img
                height="23px"
                // width="1742px"
                className=" me-5"
                src="https://winzir.ph/fs/userFiles-v2/winzirv2-18753775/images/1365-merged-16908628532289.webp"
                alt="bank-icons"
              />
            </div>
            <div className="col-4 d-flex justify-content-end">
              <img
                height="30px"
                src="https://winzir.ph/fs/userFiles-v2/winzirv2-18753775/images/2085-win-from-within-1668080617533-16774024858696.webp"
                alt="icon-win"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
